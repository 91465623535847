* {
  box-sizing: border-box;
}

body{
  padding: 0;
  margin: 0;
  font-family: "Inter", "sans-serif";
}

section{
  padding: 36px;
}
