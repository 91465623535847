.navbar{
    display:flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #672280 1.18%, #A626D3 100%);
    padding: 30px 25px;
    height: 90px;
}

.logo{
    display: flex;
    align-content: center;
}

.logo > p{
    color: #FFFFFF;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    height: 24px;

}

.logo-image{
    width: 32px;
    height: 27px;
    margin-right: 7px;
    align-self: center;
}

.logo-other-title{
    color:#FFFFFF;
    font-size: 12px;
    height: 14px;
}