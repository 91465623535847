* {
  box-sizing: border-box;
}

body{
  padding: 0;
  margin: 0;
  font-family: "Inter", "sans-serif";
}

section{
  padding: 36px;
}


.navbar{
    display:flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #672280 1.18%, #A626D3 100%);
    padding: 30px 25px;
    height: 90px;
}

.logo{
    display: flex;
    align-content: center;
}

.logo > p{
    color: #FFFFFF;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    height: 24px;

}

.logo-image{
    width: 32px;
    height: 27px;
    margin-right: 7px;
    align-self: center;
}

.logo-other-title{
    color:#FFFFFF;
    font-size: 12px;
    height: 14px;
}
.main--container{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 477px;
}

.image--container{    
    max-height: 268px; 
    max-width: 477px;
    margin: 36px 0 36px 0;
    position: relative;
    text-align: center;
}

.image--container > img{
    height: 268px;    
    width: 477px;
}

.top{
    top: 16px;
}

.bottom{
    top: 222px;
}

.image--text{
    width: 80%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 32px;
    font-weight: 900;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    color: #FFFFFF;
}

.main--inputs{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.main--inputs > input{
    width: 90%;
    height: 35px;
    border: 1px solid #D5D4D8;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
}

.main--btn{
    background: linear-gradient(90.41deg, #711F8D 1.14%, #A818DA 100%);
    height: 40px;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.main--btn-add {
    background: linear-gradient(90.41deg, #711F8D 1.14%, #A818DA 100%);
    border-radius: 5px;
    border: none;
}

.main--btn > img{
    height: 20px;
    width: 20px;
    margin-left: 3px;
}

.image--downloader{
 display: none;
}
.dragable {
    z-index: 10;
    position: relative;
    text-align: center;
    cursor: move;
}
